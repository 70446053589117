import React, { createContext, useState } from 'react';

const { Provider, Consumer } = createContext();

const ContextStore = ({ comp }) => {

    const [isMobile, setIsMobile] = useState(false);
    const [isDark, setIsDark] = useState(false);
    const [optionSidebar, setOptionSidebar] = useState('home');
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth
    });
    const [modal, setModal] = useState({
        showModal:  false,
        closeModal: null,
        component:  null,
        customCss:  null,
        size:       'xl',
        bckdrop:   null
    });

    const contextValues = {
        isDark, setIsDark,
        optionSidebar, setOptionSidebar,
        isMobile, setIsMobile,
        modal, setModal,
        windowSize, setWindowSize,
    };

    return <Provider value={contextValues}>
        {comp}
    </Provider>
} 

const WrapperConsumer = (Component) => {
    return (props) => (
        <Consumer>
            {context => <Component {...props} context={context} />}
        </Consumer>
    )
};

export default WrapperConsumer;
export { ContextStore, Consumer };