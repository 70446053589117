import React from 'react';
import { lazy } from '@loadable/component';

/* Components */
const Home = lazy(() => import('../Components/Home'));
const Plans = lazy(() => import('../Components/Plans'));
const Forgot = lazy(() => import('../Components/Forgot'));
const RecoveryPwd = lazy(() => import('../Components/RecoveryPwd'));
const Proyects = lazy(() => import('../Components/Proyects'));
const ExpoTeach = lazy(() => import('../Components/ExpoSchool'));
const CreateAccount = lazy(() => import('../Components/CreateAccount'));
const LoginAccount = lazy(() => import('../Components/LoginAccount'));
const DocumentsLegal = lazy(() => import('../Components/LegalDocuments'));
const WalletTeacher = lazy(() => import('../Components/WallTeacher'));
const RegisterTeacher = lazy(() => import('../Components/CreateAccountTeacher'));
const Windows = lazy(() => import('../Components/Windows'));

/* Components student */
const HomeStudent = lazy(() => import('../Components/HomeStudent'))

const paths = [
    {
        path: '/',
        element: <Home />
    },
    {
        path: '/buy/student/:plan/',
        element: <Plans />
    },
    {
        path: '/forgot-password/',
        element: <Forgot />
    },
    {
        path: '/change-password/:studentid/:token/',
        element: <RecoveryPwd />
    },
    {
        path: '/register-student/',
        element: <CreateAccount />
    },
    {
        path: '/login-student/',
        element: <LoginAccount />
    },
    {
        path: '/expo/students/',
        element: <ExpoTeach />
    },
    {
        path: '/proyect/student/',
        element: <Proyects />
    },
    {
        path: '/wall/Teacher/:name/',
        element: <WalletTeacher />
    },
    {
        path: '/register-teacher/',
        element: <RegisterTeacher />
    },
    {
        path: '/legal/:type/document/:from/',
        element: <DocumentsLegal />
    },
    {
        path: '/window/student/:studentid/:type_window/',
        element: <Windows />
    },
    // Home Student
    {
        path: '/dashboard-student/:studentid/academic',
        element: <HomeStudent />
    },
    {
        path: '/courses/:studentid/:option',
        element: ''
    }
];

export default paths;